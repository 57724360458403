.is-sidebar-listitem {
  display: block;
  margin: 5px;
}

.is-sidebar-listitem-icon {
  margin-right: 5px;
}

.is-sidebar-child-listitem {
  display: block;
  margin-left: calc(21px + 19px);
  cursor: pointer;
}