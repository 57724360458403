/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #fbfbfb;
  padding: 2.5em 1.5em 0;
}

/* Wrapper for item list */
.bm-item-list a {
  color: #464646;
}

/* Individual item */
.bm-item {
  display: inline-block;
}