:root {
  --padding: 32px;
  --background-color-light: #FAFAFA;
  --color-isabelline: #EEEEEE;
  --checkbox-main-color: #48c774;
}

html {
  /* background-image: url('./Assets/logo-zeuslab-light.png'); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  z-index: -1;
}

.access-iframe {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 999999;
}


/* Custom class based on Bulma */

.has-100-margin-top {
  margin-top: 100px;
}

.has-150-margin-top {
  margin-top: 150px;
}

.is-cm-fullheight {
  min-height: 80vh;
}

.has-margin-top-bottom-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.has-30-width {
  width: 30px !important;
}

.has-110-fixed-width {
  width: 110px !important;
}

.has-130-fixed-width {
  width: 130px !important;
}

.has-140-fixed-width {
  width: 140px !important;
}

.has-150-fixed-width {
  width: 150px !important;
}

.has-fullwidth {
  width: 100%;
}

.is-quite-large {
  width: 120px !important;
  height: 40px !important;
  font-size: 1.1rem !important;
}

.is-white-button {
  background-color: #fff !important;
  border: 1px solid #707070 !important;
  color: #707070 !important;
  padding: 0px 5px;
}

.is-bordered-column {
  margin: 10px 20px;
  padding: 0px 5px 5px;
  border-radius: 8px;
}

.title-area {
  margin-top: -25px;
  padding: 5px;
}

.has-margin-right-10 {
  margin-right: 10px!important;
}

.has-text-no-wrap {
  white-space: nowrap;
}

.is-white-button:hover {
  background-color: var(--background-color-light) !important;
}

.has-10-padding {
  padding: 10px;
}

.has-20-margin-top {
  margin-top: 20px;
}

.has-10-margin-left {
  margin-left: 10px;
}

.has-300-max-width {
  max-width: 300px;
}

.has-450-max-width {
  max-width: 650px;
}

.is-readonly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-grey-color {
  color: #464646 !important;
}

.has-fixed-width {
  max-width: 140px;
  width: 140px
}

.has-small-fixed-width {
  max-width: 20px;
  width: 20px;
}

.custom-card-rounded {
  background-color: #fff;
  padding: var(--padding) !important;
  border: 1px solid var(--color-isabelline);
  box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, .2);
  border-radius: 15px;
}

.custom-card-light {
  background-color: var(--background-color-light);
  border: 1px solid var(--color-isabelline);
  box-shadow: 0 3px 4px var(--color-isabelline);
  padding: 15px!important
}

.is-right {
  float: right;
  margin: 16px
}

.is-clickable {
  cursor: pointer;
}

.has-no-border {
  border: 0!important;
}

.is-256x256 {
  width: 256px;
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.is-200x200 {
  width: 200px;
  height: 200px;
}

.is-180x180 {
  width: 180px;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.is-image-centered {
  margin: 0 auto;
}

.file-input-upload {
  margin-bottom: .75rem;
}

.file-input-upload .file-cta {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 140px !important;
  min-width: 140px !important
}

.file-input-upload .file-name {
  width: 100%;
}

.padded-input {
  padding-right: 10px;
  padding-left: 10px;
}

.file-input-upload .file-name {
  max-width: 100%;
  min-width: 10rem;
}

.no-photo-message {
  display: block;
  text-align: center;
  margin: 20px
}

.figure-profile-picture {
  margin: auto;
  margin-bottom: .75rem;
}

.figure-profile-picture-input label {
  width: 100%
}

.file-input-upload .file-name {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.has-no-margin {
  margin: 0 !important;
}

.is-sidebar-listitem {
  display: block;
  margin: 5px;
}

.is-sidebar-listitem-icon {
  margin-right: 5px;
}

.is-sidebar-child-listitem {
  display: block;
  margin-left: calc(21px + 19px);
  cursor: pointer;
}

.is-sidebar-child-listitem:hover {
  /* color: #3273dc; */
}

.modali-body {
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  max-height: 80vh;
  overflow-y: auto;
}

.text-is-aligned-right {
  text-align: right!important;
}

.is-display-inherit {
  display: inherit!important;
}


/* override width bulma columns */

.column.is-one-fifth,
.column.is-one-fifth-tablet {
  width: 10% !important;
}

.bordered-menu {
  border-top: 1px solid #CCCCCC !important;
  border-bottom: 1px solid #CCCCCC !important;
}

.hero-body {
  padding: 1rem .5rem !important;
}

.section {
  padding: .6rem 1.5rem !important;
}

tbody tr td {
  white-space: normal;
}

.react-datepicker__input-container {
  display: flex!important;
  margin-bottom: .75rem!important;
  margin-top: .75rem!important;
}


/* Collapsible classes */

.Collapsible-info {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  padding: 20px;
  margin: 20px 0 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.is-parent-collapsible-trigger {
  display: inline-flex;
  width: 100%;
}

.is-icon-aligned-right {
  text-align: right;
  width: 100%;
}


/* End collapsible classes */


/* Buttons */

.button[disabled],
fieldset[disabled] .button {
  opacity: 1!important;
}


/* End buttons*/

.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}

.InputFromTo .DayPicker-Day {
  border-radius: 0 !important;
}

.InputFromTo .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.InputFromTo .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.InputFromTo .DayPickerInput-Overlay {
  width: 550px;
}

.InputFromTo-to .DayPickerInput-Overlay {
  margin-left: -198px;
}

.DayPickerInput {
  width: 100%
}

.DayPickerInput input {
  background-color: #fff;
  border-color: #dbdbdb;
  border-radius: 4px;
  color: inherit;
  max-width: 100%;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  border: 1px solid #dbdbdb;
  width: 100%;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  position: relative;
  vertical-align: top;
}


/* custom styled checkbox */

.checkbox-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox-input {
  position: relative !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 8px;
  box-sizing: content-box;
  overflow: hidden;
  background-color: #fff;
}

.checkbox-input:before {
  content: '';
  display: block;
  box-sizing: content-box;
  width: 25px;
  height: 25px;
  border: 2px solid #dbdbdb;
  transition: 0.2s border-color ease;
}

.checkbox-input:checked:before {
  border-color: var(--checkbox-main-color);
  transition: 0.5s border-color ease;
}

.checkbox-input:disabled:before {
  border-color: #ccc;
  background-color: whitesmoke;
}

.checkbox-input:after {
  content: '';
  display: block;
  position: absolute;
  box-sizing: content-box;
  top: 50%;
  left: 50%;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  background-color: var(--checkbox-main-color);
  width: 16px;
  height: 16px;
  border-radius: 100vh;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
}

.checkbox-input[type="radio"]:before {
  border-radius: 100vh;
}

.checkbox-input[type="radio"]:after {
  width: 16px;
  height: 16px;
  border-radius: 100vh;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
}

.checkbox-input[type="radio"]:checked:after {
  -webkit-animation: toggleOnRadio 0.2s ease forwards;
  animation: toggleOnRadio 0.2s ease forwards;
}

.checkbox-input[type="checkbox"]:before {
  border-radius: 4px;
}

.checkbox-input[type="checkbox"]:after {
  width: 9.6px;
  height: 16px;
  border-radius: 0;
  -webkit-transform: translate(-50%, -85%) scale(0) rotate(45deg);
  transform: translate(-50%, -85%) scale(0) rotate(45deg);
  background-color: transparent;
  box-shadow: 4px 4px 0px 0px var(--checkbox-main-color);
}

.checkbox-input[type="checkbox"]:checked:after {
  -webkit-animation: toggleOnCheckbox 0.2s ease forwards;
  animation: toggleOnCheckbox 0.2s ease forwards;
}

.checkbox-input[type="checkbox"].filled:before {
  border-radius: 4px;
  transition: 0.2s border-color ease, 0.2s background-color ease;
}

.checkbox-input[type="checkbox"].filled:checked:not(:disabled):before {
  background-color: var(--checkbox-main-color);
}

.checkbox-input[type="checkbox"].filled:not(:disabled):after {
  box-shadow: 4px 4px 0px 0px white;
}

@-webkit-keyframes toggleOnCheckbox {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -85%) scale(0) rotate(45deg);
    transform: translate(-50%, -85%) scale(0) rotate(45deg);
  }
  70% {
    opacity: 1;
    -webkit-transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
    transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
  }
  100% {
    -webkit-transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
    transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
  }
}

@keyframes toggleOnCheckbox {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -85%) scale(0) rotate(45deg);
    transform: translate(-50%, -85%) scale(0) rotate(45deg);
  }
  70% {
    opacity: 1;
    -webkit-transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
    transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
  }
  100% {
    -webkit-transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
    transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
  }
}

@-webkit-keyframes toggleOnRadio {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
  }
  70% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.9);
    transform: translate(-50%, -50%) scale(0.9);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(0.8);
    transform: translate(-50%, -50%) scale(0.8);
  }
}

@keyframes toggleOnRadio {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
  }
  70% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.9);
    transform: translate(-50%, -50%) scale(0.9);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(0.8);
    transform: translate(-50%, -50%) scale(0.8);
  }
}


/* ANIMATIONS */

.has-animation {
  animation-duration: .3s;
  animation-name: appear;
}

.has-fadeout {
  animation-duration: .3s;
  animation-name: disappear;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 1025px) {
  .display-mobile-block {
    display: block!important;
  }
}


/* override larghezza colonne tabelle */


/* rilevazioni articolo */

.tableRilevazioniArticolo tr th:nth-child(2) {
  max-width: 150px!important;
  width: 150px!important;
}

.tableRilevazioniArticolo tr th:nth-child(3) {
  max-width: 150px!important;
  width: 150px!important;
}


/* macchinari */

.tableMacchinari tr th:nth-child(1) {
  max-width: 150px!important;
  width: 150px!important;
}

.tableMacchinari tr th:nth-child(4) {
  max-width: 150px!important;
  width: 150px!important;
}

.tableMacchinari tr th:nth-child(5) {
  max-width: 150px!important;
  width: 150px!important;
}


/* personale */

.tablePersonale tr th:nth-child(1) {
  max-width: 150px!important;
  width: 150px!important;
}

.tablePersonale tr th:nth-child(3) {
  max-width: 150px!important;
  width: 150px!important;
}

.tablePersonale tr th:nth-child(4) {
  max-width: 150px!important;
  width: 150px!important;
}


/* persone */

.tablePersone tr th:nth-child(2) {
  max-width: 150px!important;
  width: 150px!important;
}

.tablePersone tr th:nth-child(3) {
  max-width: 150px!important;
  width: 150px!important;
}

.tablePersone tr th:nth-child(4) {
  max-width: 150px!important;
  width: 150px!important;
}

.tablePersone tr th:nth-child(5) {
  max-width: 150px!important;
  width: 150px!important;
}


/* articoli in uscita */

.tableArticoliInUscita tr th:nth-child(1) {
  max-width: 150px!important;
  width: 150px!important;
}

.tableArticoliInUscita tr th:nth-child(3) {
  max-width: 100px!important;
  width: 100px!important;
}

.tableArticoliInUscita tr th:nth-child(4) {
  max-width: 100px!important;
  width: 100px!important;
}

.tableArticoliInUscita tr th:nth-child(5) {
  max-width: 100px!important;
  width: 100px!important;
}

.tableArticoliInUscita tr th:nth-child(6) {
  max-width: 100px!important;
  width: 100px!important;
}

.tableArticoliInUscita tr th:nth-child(7) {
  max-width: 100px!important;
  width: 100px!important;
}

.react-datepicker-popper {
  z-index: 999;
}

.react-datepicker-time__caption {
  visibility: hidden;
  position: relative;
}

.react-datepicker-time__caption:before {
  content: 'Ore';
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
}