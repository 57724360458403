.has-inactive-border {
  border: 3px solid gray;
  margin: 1px;
}

.has-5-margin-top {
  margin-top: 5px
}

.is-vertical-30 {
  height: 30vh;
}

.is-vertical-40 {
  height: 40vh;
}

.is-vertical-20 {
  height: 20vh;
}

.has-100-height {
  height: 100%;
}

.has-10-margin-left {
  margin-left: 10px;
}

.quick-operation-table td,
.quick-operation-table th {
  border: 0!important
}

.column button {
  margin-left: 0!important;
}

.has-little-padding {
  padding: 1px
}

.is-label-colli {
  max-width: 300px;
  margin: 0 auto;
}

.has-warning-background .field {
  background-color: hsl(48, 100%, 67%)!important;
}

.is-input-colli {
  background-color: white!important;
}

.is-label-colli .field {
  background-color: whitesmoke;
  padding: 18px;
  border-radius: 4px;
}

.is-label-colli-no-background {
  max-width: 300px;
  margin: 0 auto;
}

.is-label-colli-no-background .field {
  padding: 18px;
  border-radius: 4px;
}

.is-scrollable {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 93%;
}

.is-loading-bar {
  margin-top: 40vh!important;
  max-width: 300px!important;
  margin: 0 auto;
}

.tile {
  word-wrap: anywhere;
}

@media only screen and (max-width: 1170px) {
  .is-scrollable {
    height: 90%;
  }
}

.is-image-cover {
  object-fit: cover;
}


/* snackbar */

#confirmation-message {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #48c774;
  color: white;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

#confirmation-message.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}