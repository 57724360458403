.login-body {
  background-color: #FFFFFF;
  height: 100vh;
  overflow: hidden;
}

.login-card {
  max-width: 500px;
}

.login-button {
  display: block!important;
  margin: 0 auto!important;
  width: 150px;
  margin-top: 40px!important;
  box-shadow: 0 1px 1px 0 rgba(60,64,67,.08),0 1px 5px 1px rgba(60,64,67,.16)!important;
}