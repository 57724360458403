/* helpers */

.has-border-bottom {
  border-bottom: 1px solid #eee;
}

.has-10-margin-right {
  margin-right: 10px;
}

.has-30-margin-top {
  margin-top: 30vh;
}

.has-border-right-green {
  border-right: 2px solid #a2cea5;
}

.is-fullheight-tablet {
  height: 93vh;
}

.is-fixed-bottom {
  position: absolute;
  width: 20%;
  bottom: 30px;
}

.is-deep-index {
  z-index: 0;
}

.is-scrollable-column {
  max-height: calc(100% - 170px);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.is-link-disabled {
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
}

.is-scrollable-half-column {
  height: 44%;
  overflow-y: auto;
  overflow-x: hidden;
}

.is-few-padding {
  padding: 0.25rem!important;
}

.has-margin-title {
  margin-top: .8rem!important;
  margin-bottom: .8rem!important;
}

.is-light-warning {
  background: rgba(255, 221, 87, .6);
}

.fade-from-right {
  animation: fadeFromRight .5s forwards;
}

.fade-from-bottom {
  animation: fadeFromBottom .5s forwards;
}

.notification {
  padding: 1rem 2rem 1rem 1rem!important;
}

@keyframes fadeFromRight {
  from {
    transform: translateX(50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeFromBottom {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}