.navbar-menu {
  background-color: #fbfbfb;
  border: 1px solid #eee;
  box-shadow: 0 3px 4px #eee;
}

.logo-image {
  margin: 0 auto;
  padding-top: 10px;
  max-width: 120px;
}