.has-editable-control {
    background-color: rgba(255, 204, 0, 0.4);
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
}

.has-editable-control input {
    background-color: transparent;
    border: 0;
}

.has-small-column-size {
    max-width: 200px;
    width: 200px;
}

.has-medium-column-size {
    max-width: 300px;
    width: 300px;
}

.has-large-column-size {
    max-width: 400px;
    width: 400px;
}

.table-dropdown-list {
    border: 0!important
}

.has-border {
    border: 1px solid #eee
}