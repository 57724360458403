.table th {
    text-align: center!important;
}

.table-cell-dark {
    background-color: #efefef;
    border: 1px solid #dbdbdb;
}

.text-is-aligned-left {
    text-align: left!important;
}

.display-flex {
    display: flex;
}

.column button {
    margin-left: 10px;
}

.isActive {
    background-color: rgba(148, 169, 57, 20%);
}

.title-area .title {
    background-color: #fafafa;
    padding: 0 10px;
}

.editable-input {
    border: 0;
    background-color: transparent;
    height: 100%;
    width: 100%;
}

.parent {
    border: 1px solid #ccc;
    margin: 0 auto;
    padding: 0px 20px 20px;
    border-radius: 8px;
    margin-top: 30px;
}

.no-wrap {
    white-space: nowrap;
}

.title-area {
    margin-top: -14px;
}

.custom-table-input {
    width: 100%;
    height: 100%;
}

.table.is-narrow td,
.table.is-narrow th {
    padding: 0.25em 0.5em;
}

.status-programmato {
    color: skyblue;
}

.status-incorso {
    color: #3abb67;
}

.status-concluso {
    color: #86533f;
}

.status-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 50ch;
    overflow: hidden;
}