.bordered-menu {
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  padding: 15px 0 5px 0;
}

.active-element {
  color: #219537!important;
}

.has-no-border-left {
  border-left: 0!important;
  margin: 0!important;
}

.has-no-border-bottom {
  border-bottom: 0!important;
  padding: 15px 0 15px 0!important;
}

.horizontal-menu-item {
  display: flex;
}

.horizontal-menu-item svg {
  height: auto;
}